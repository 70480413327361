<template>
  <BasicLayout class="is-small">
    <template v-slot:content>
      <h1 class="title">Definir nova password</h1>

      <b-message v-if="errors.length" type="is-danger">
        <div v-for="error in errors" :key="error.key">
          <div v-if="error.key == 'InvalidRecoverPasswordCode'">
            O código de recuperação não é válido.
          </div>
          <div v-else-if="error.key == 'MaxAttempts'">
            Foi ultrapassado o limite de tentativas.<br />Por favor, tente mais tarde.
          </div>
          <div v-else-if="error.key == 'MinStringLength'">
            A password precisa conter pelo menos 8 caracteres.
          </div>
          <div v-else-if="error.key == 'ThreeLettersRequired'">
            A password precisa conter pelo menos 3 letras.
          </div>
          <div v-else>
            <p>Não foi registar a sua nova conta.</p>
            <p>{{ error.message }}</p>
          </div>
        </div>
      </b-message>

      <b-message v-if="passwordUpdated" type="is-primary">
        <p class="is-size-3">A sua password foi atualizada com sucesso.</p>
      </b-message>

      <div v-if="passwordUpdated">
        <b-button type="is-primary" expanded @click="$router.push('/login')">Entrar</b-button>
      </div>

      <form v-if="!passwordUpdated" @keypress.enter="doConfirm" @submit.prevent="doConfirm">
        <b-field
          label="Nova Password"
          label-for="password"
          :type="{ 'is-danger': $v.password.$error }"
          :message="$v.password.$error ? $t('login.setPassword') : ''"
        >
          <b-input v-model="password" password-reveal type="password" name="password" />
        </b-field>
        <p class="instructions">
          Defina uma password segura, com pelo menos 8 caracteres, entre os quais pelo menos 3 devem ser letras.
        </p>

        <b-field
          label="Confirmar nova password"
          label-for="confirm-password"
          :type="{ 'is-danger': $v.confirmPassword.$error }"
          :message="$v.confirmPassword.$error ? 'A password não é igual' : ''"
        >
          <b-input v-model="confirmPassword" password-reveal type="password" name="confirm-password" />
        </b-field>
        <b-field class="has-margin-top-medium">
          <b-button expanded type="is-primary" @click="doConfirm">{{ $t('continue') }}</b-button>
        </b-field>
      </form>

      <div v-if="!passwordUpdated" class="has-margin-top-medium has-text-centered">
        <router-link to="/login">{{ $t('login.open') }}</router-link>
      </div>
    </template>
  </BasicLayout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import BasicLayout from '@/components/layouts/BasicLayout'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BasicLayout,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordUpdated: false,
      errors: [],
    }
  },

  validations: {
    password: {
      required,
    },
    confirmPassword: {
      confirm() {
        return this.password == this.confirmPassword
      },
    },
  },

  methods: {
    doConfirm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        AuthService.recoverConfirm(this.$route.params.hash, this.password, this.confirmPassword)
          .then(() => {
            this.errors = [] // reset aos erros
            this.passwordUpdated = true
          })
          .catch(error => {
            this.errors = error.response.data.__errors__

            this.$buefy.snackbar.open({
              message: 'Ocorreu um erro',
              type: 'is-danger',
              position: 'is-bottom-right',
              duration: 4000,
            })

            // Garante que o utilizador vê o erro
            window.scrollTo(0, 0)
          })
      }
    },
  },
}
</script>
