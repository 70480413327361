<template>
  <div class="basic-layout">
    <div class="main">
      <div class="inner">
        <LanguageNav class="hidden" />
        <div class="main-header">
          <div class="app-icon">
            <icon name="logo-apply" class="icon logo-apply x2" />
          </div>
          <div class="app-description">
            {{ $t('pageDescription') }}
          </div>
        </div>
        <div class="main-content">
          <div class="inner">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageNav from '@/components/LanguageNav'

export default {
  components: {
    LanguageNav,
  },
}
</script>
